<template>
	<div class="goods-box wrapper">
		<div class="skeleton" v-if="loading">
			<el-skeleton :rows="25" animated :loading="loading" />
		</div>
		<div class="goods">
			<div class="goods-image">
				<el-carousel height="400px" v-if="goods">
					<el-carousel-item v-for="(item, index) in goods.goods_images" :key="index">
						<el-image :src="item"></el-image>
					</el-carousel-item>
				</el-carousel>
			</div>
			<div class="goods-content" v-if="goods">
				<div class="goods-title">{{ goods.goods_title }}</div>
				<div class="coupon-boxi">
					<div class="coupon-text">券</div>
					<div class="coupon-money">¥ {{ goods.coupon_amount }}</div>
				</div>
				<div class="goods-price">
					<span class="price-text">券后价：</span>
					<span class="price">¥ {{ goods.price }}</span>
				</div>
				<div class="goods-tools">
					<div class="tools-left">
						<span class="like"><img src="@/assets/pc_images/like.png" alt=""></span>
						<span class="like-text">100%</span>
						<span class="volume" v-if="goods.volume > 100">月销量：{{ goods.volume }}</span>
					</div>
				</div>
				<div class="mall">
					<i class="el-icon-s-shop"></i>
					<span>{{ goods.shop_name }}</span>
				</div>
				<div class="buy">
					<div class="tools-right">
						<span class="platform">{{ goods.platform_cn?goods.platform_cn:goods.platform }}</span>
						<span class="buy-btn" @click="_loadGoodsUrl">查看详情</span>
					</div>
				</div>
			</div>
		</div>

		<div class="goods-detail">
			<ul v-if="details.length>0">
				<li v-for="(image, index) in details" class="detail-image-item" :key="index">
					<el-image :src="image" lazy></el-image>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import Vue from 'vue';
import Coupon from '@/views/pc/components/Coupon'
import { Skeleton, SkeletonItem, Card, Carousel, CarouselItem, Image, Empty, Icon } from 'element-ui';

Vue.use(Image).use(Skeleton).use(SkeletonItem).use(Card).use(Carousel).use(CarouselItem).use(Empty).use(Icon)

import {getProductDetail, getDetailContent} from '@/api/product'
import { getGoodsUrl } from '@/api/pc'

export default {
	components: {
		Coupon
	},
	data () {
		return {
			loading: true,
			goods: null,
			details: []
		}
	},
	computed: {},
	created () {},
	mounted () {
		this._loadData()
	},
	methods: {
		_loadData() {
			this.loading = true
			getProductDetail({goods_id: this.$route.query.id}).then(res => {
				this.loading = false
				if(res.errCode === 0) {
					this.goods = res.data
					this._loadDetail()
				}
			})
		},
		_loadDetail() {
			getDetailContent({goods_id: this.$route.query.id}).then(res => {
				if(res.errCode === 0) {
					this.details = res.data.data
				}
			})
		},
		_loadGoodsUrl() {
			if(this.goods_url) {
				this._goto()
			}
			getGoodsUrl({goods_id: this.goods.goods_id}).then(res => {
				if(res.errCode === 0) {
					this.goods_url = res.data.coupon_click_url
					this._goto()
				}
			})
		},
		_goto() {
			window.open(this.goods_url);
		}
	}
}
</script>

<style scoped lang="scss">
@import "~@/css/variables.scss";

.skeleton {
	margin-top: 10px;
	background: #fff;;
	padding: 20px;
}

.goods-box {
	margin-top: 10px;
	min-height: 100vh;
}

.goods {
	display: flex;
	margin-bottom: 10px;
	background: #fff;
	padding: 20px;
}

.goods-image {
	width: 400px;
	height: 400px;
	margin-right: 30px;
}


.goods-content {
	flex: 1;
	height: 400px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.goods-title {
	height: 80px;
	overflow: hidden;
	line-height: 25px;
	font-size: 22px;
	line-height: 40px;
	color: #333;
}

.goods-price {
	padding: 20px 0;
	font-size: 22px;
	color: $--price-color;
	font-weight: 600;
}

.goods-tools {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.coupon-boxi {
	box-sizing: border-box;
	padding: 20px 0;
	display: flex;
	align-items: center;
}

.coupon-text {
	padding: 0px 8px;
	margin: 0;
	background: #fd7100;
	color: #fff;
	font-size: 16px;
	background: linear-gradient(0deg, #ff4b42, #ff753c);
	height: 22px;
	vertical-align: middle;
	line-height: 22px;
}

.coupon-money {
	padding: 0 2px;
	margin: 0;
	border: 1px solid #fd7100;
	color: #fd7100;
	font-size: 16px;
	height: 22px;
	vertical-align: middle;
	line-height: 22px;
}

.tools-left {
	display: flex;
	align-items: center;
}
.like {
	width: 15px;
	height: 15px;
	font-size: 0;
	margin-right: 7px;
}
.like img {
	width: 100%;
}
.like-text {
	font-size: 12px;
	color: #888;
	margin-right: 10px;
}
.volume {
	font-size: 13px;
	color: #555;
}

.tools-right {
	display: flex;
	align-items: center;
}

.platform {
	padding: 3px 8px;
	background: #302516;
	color: #e5cb97;
	font-size: 12px;
	border-radius: 3px;
	margin-right: 20px;
}

.buy-btn {
	width: 120px;
	height: 40px;
	border-radius: 5px;
	background: linear-gradient(to left, $--color-primary, $--price-color);
	line-height: 40px;
	color: #fff;
	font-weight: 500;
	font-size: 18px;
	text-align: center;
	cursor: pointer;
}
.mall {
	padding: 20px 0;
	color: #666;
	font-size: 16px;
}

.buy {
	display: flex;
	justify-content: flex-end;
}

.detail-image-item {
	font-size: 0;
}
.detail-image-item /deep/ .el-image{
	width: 100%;
}
</style>
